import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FullPlayer } from '@falconstudios/ns-player';

import { DashboardController } from '../../../controllers/dashboard-controller/DashboardController';
import ImageLoadingSkeleton from '../../../components/ImageLoadingSkeleton/ImageLoadingSkeleton';

import './TwelveDaysOfXXXmas.scss';

const BANNERS = {
  top: [
    'https://spotlight.nakedcdn.com/nakedsword/img/12daysofxxxmas/top-12daysofxxxmas-2000.jpg',
    'https://spotlight.nakedcdn.com/nakedsword/img/12daysofxxxmas/top-12daysofxxxmas-1200.jpg',
    'https://spotlight.nakedcdn.com/nakedsword/img/12daysofxxxmas/top-12daysofxxxmas-640.jpg'
  ],
  bottom: [
    'https://spotlight.nakedcdn.com/nakedsword/img/12daysofxxxmas/btm-12daysofxxxmas-2000.jpg',
    'https://spotlight.nakedcdn.com/nakedsword/img/12daysofxxxmas/btm-12daysofxxxmas-1200.jpg',
    'https://spotlight.nakedcdn.com/nakedsword/img/12daysofxxxmas/btm-12daysofxxxmas-640.jpg'
  ]
};

export default function TwelveDaysOfXXXmas() {
  const [state, setState] = useState({
    title: '',
    titleNs: '',
    studios: [],
    cover_images: [],
    video_url: {},
    loading: true
  });
  const {
    title,
    titleNs,
    studios = [],
    cover_images = [],
    video_url = {},
    loading
  } = state;

  useEffect(() => {
    axios.get('/frontend/freebie/scene/get')
      .then(resp => {
        const data = resp?.data?.data || {};
        setState(prevState => ({ ...prevState, ...data, loading: false }));
      })
      .catch(err => {
        console.log(err);
        setState(prevState => ({ ...prevState, loading: false }));
      });
  }, []);

  const renderBanner = (images = []) => {
    if (loading) return <ImageLoadingSkeleton className='BannerLoader' />;

    const sizes = [1200, 640, 320];

    return (
      <a href='/join'>
        <picture className='TW-Banner'>
          {images.map((img, i) => {
            return <source key={i} srcSet={img} media={`(min-width: ${sizes[i]}px)`} />
          })}
          <img src={images[1]} alt='Banner' />
        </picture>
      </a>
    )
  };

  const renderPlayer = () => {
    if (loading) return <ImageLoadingSkeleton className='PlayerLoader' />;

    if (!video_url || Object.keys(video_url).length === 0) return null;

    return (
      <FullPlayer data={{
        autoplay: false,
        progressBarColors: DashboardController.getPlayerConfig().progressBarColors,
        isUserLoggedIn: () => true, // for Chromecast
        videos: [{
          sources: [{
            src: video_url.hls,
            smoothStream: video_url.msss
          }],
          poster: cover_images?.[0]?.url || '',
          name: titleNs || title
        }],
      }} />
    )
  };

  const renderDescription = () => {
    return (
      <div className='TW-Description'>
        <h3>NAKEDSWORD PRESENTS: 12 DAYS OF XXX-MAS</h3>
        <p>
          Santa's coming more than once this year, and so will you! Visit NakedSword for the "12 Days of XXX-mas", and find a new, free scene every day through December 25th. So, drop your tinsel, grab your yule log, and stay tuned to this space for an explosive white Christmas.
        </p>
      </div>
    )
  };

  const renderTitle = () => {
    const studioName = studios?.[0]?.name ? `${studios?.[0]?.name}:` : '';
    return (
      <h2 className='TW-Title'>{studioName} <span>{titleNs || title}</span></h2>
    )
  };

  const renderPromoDescription = () => {
    return (
      <p className='TW-PromoDescription'>Want to see more? Take advantage of this holiday offer:</p>
    )
  };

  return (
    <div className='TwelveDaysOfXXXmas'>
      {renderBanner(BANNERS.top)}

      <div className='TW-Content'>
        {renderDescription()}
        {renderTitle()}
        {renderPlayer()}
        {renderPromoDescription()}
      </div>

      {renderBanner(BANNERS.bottom)}
    </div>
  )
};
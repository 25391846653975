import PropTypes from 'prop-types';
import { env } from '../../env';

export const whiteLabelPropertyID = parseInt(env.REACT_APP_PROPERTY_ID);

export const PROPERTY_ID = {
  nakedSword: 1,
  guerillaPorn: 22,
  trentonDucati: 16,
  huntForMen: 8,
  barracksboys: 25,
  mrMan: 20,
  qReel: 19,
  timVideovault: 26,
  tripleX: 11,
};

// IF you want to show something only for huntformen, pass the ID of huntformen as array

export const RenderConditionalComponent = ({ ids = [], children }) => {
  let view = children;

  if (!Array.isArray(ids)) {
    console.error(`RenderConditionalComponent - ${ids} must be an array!`);
    return null;
  }

  if (ids.length === 0) {
    console.error(`RenderConditionalComponent - ids prop not provided!`);
    return null;
  }

  if (!ids.includes(whiteLabelPropertyID)) {
    view = null;
  }

  return <>{view}</>;
};

RenderConditionalComponent.propTypes = {
  ids: PropTypes.array,
  children: PropTypes.node.isRequired,
};

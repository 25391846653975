import React, { useState, useEffect, useRef } from 'react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import ApplicationLogo from '../../../components/ApplicationLogo/ApplicationLogo';
import BrowseNavigation from '../../../components/BrowseNavigation/BrowseNavigation';
import MainSearch from '../../../components/MainSearch/MainSearch';
import UserAction from '../../../components/UserAction/UserAction';
import { ReactComponent as AgeVerifiedIcon } from '../../../images/ageVerifiedIcon.svg';
import { ReactComponent as SearchIcon } from '../../../images/NS_search_icon.svg';
import { ReactComponent as CloseSearchIcon } from '../../../images/NS_close_search_icon.svg';
import { createJoinLinkFromLastCode } from '../../../links';
import { isUserLoggedIn } from '../../../services/token-service/token.service';
import { getUserNATSCode } from '../../../services/nats-service/nats.service';
import { UsersController } from '../../../controllers/users-controller/users.controller';
import { showMyAccountPage } from '../../../services/navigation/navigation.service';
import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';
import { getCookie, isWeb } from '../../../services/util-service/util.service';
import { DashboardController } from '../../../controllers/dashboard-controller/DashboardController';
import NavigationLinks from '../../../components/NavigationLinks/NavigationLinks';

import './MainDashboardHeader.scss';

const MainDashboardHeader = (props) => {
  const { handleSearchDropdownValue, toggleMobileMenu } = props;
  const [desktopSearchVisible, setDesktopSearchVisible] = useState(false);
  const ageVerContentRef = useRef(null);
  const ageVerVisible = useRef(false);
  const { t } = useTranslation();
  const userLoggedIn = isUserLoggedIn();
  const { header_links, browse_config } = DashboardController.getWhitelabelConfig();

  useEffect(() => {
    const onClickOutside = (event) => {
      const elem = event.target;

      // search
      if (elem.classList.contains('SearchIcon')) {
        return;
      } else if (!elem.closest('.MainSearch')?.className.includes('MainSearch')) {
        setDesktopSearchVisible(false);
      }

      // age verification badge
      if (isWeb()) return;
      if (ageVerContentRef.current) {
        if (elem.classList.contains('AgeVerifiedContainer')) {
          ageVerVisible.current = !ageVerVisible.current;
          ageVerContentRef.current.style.display = ageVerVisible.current ? 'block' : 'none';
        } else {
          ageVerContentRef.current.style.display = 'none';
          ageVerVisible.current = false;
        }
      }
    };
    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  const renderNav = () => {
    return (
      <nav className="NavBar d-flex align-center">
        <BrowseNavigation
          className='NavBar-Btn'
          baseText='Videos'
        >
          <NavigationLinks links={browse_config} className='Dropdown-Item' />
        </BrowseNavigation>
        <NavigationLinks links={header_links} className='NavBar-Btn' />
        {renderSearch()}
      </nav>
    );
  };

  const renderSearch = () => {
    return (
      <MainSearch
        handleSearchDropdownValue={handleSearchDropdownValue}
        desktopSearchVisible={desktopSearchVisible}
        setDesktopSearchVisible={setDesktopSearchVisible}
      />
    );
  };

  const renderUserActions = () => {
    return (
      <div className="UserActions">
        {userLoggedIn && (
          <>
            <UserAction text={t('MainDashboardHeader.myAccount')} onClick={() => showMyAccountPage()} />
            <div
              onClick={() => UsersController.signOutUser(t('MainDashboardHeader.loginOutMsg'))}
              className="LoginWrapper"
            >
              <UserAction text={t('MainDashboardHeader.signOut')} />
            </div>
          </>
        )}
        {!userLoggedIn && (
          <>
            <UserAction
              text="Join Now"
              onClick={() => {
                // get latest valid code
                const getStoredCode = getUserNATSCode();
                const joinURL = createJoinLinkFromLastCode(getStoredCode);
                navigate(joinURL);
              }}
            />
            <div onClick={() => SignInController.showSignInDialog()} className="LoginWrapper">
              <UserAction text="Sign in" />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderAgeVerIcon = () => {
    let bavCookie = getCookie('bav');
    if (!bavCookie) return null;

    try {
      bavCookie = decodeURIComponent(bavCookie);
      bavCookie = window.atob(bavCookie);
    } catch (err) {
      console.error('Decoding bav failed:', err);
    }
    const bavToLocalFormat = new Date(bavCookie * 1000).toLocaleString(); // timestamp
    const validBavDate = bavToLocalFormat !== 'Invalid Date' ? bavToLocalFormat : '';

    return (
      <span className="AgeVerifiedContainer">
        <AgeVerifiedIcon />
        <div className="AgeVerifiedContent" ref={ageVerContentRef}>
          <div>Age verification successful.</div>
          <div>Verification valid until:</div>
          <div>{validBavDate}</div>
        </div>
      </span>
    );
  };

  const renderMobileIcons = () => {
    return (
      <i
        className="MobileMenuBtn fa fa-bars cursor-pointer"
        onClick={() => {
          if (toggleMobileMenu()) {
            toggleMobileMenu();
          }
          setDesktopSearchVisible(false);
        }}
      />
    );
  };

  const renderSearchIcon = () => {
    return (
      <div
        className="SearchIcon d-flex align-center justify-center"
        onClick={() => {
          if (SignInController.isSignInDisplayed()) {
            SignInController.closeSignInDialog();
          }
          setDesktopSearchVisible(!desktopSearchVisible);
        }}
      >
        {desktopSearchVisible ? <CloseSearchIcon className="CloseSearchSvg" /> : <SearchIcon />}
      </div>
    );
  };

  return (
    <header className="MainDashboardHeader d-flex align-center">
      <ApplicationLogo />
      {renderNav()} {/* hidden in mobile view*/}
      <div className="d-flex align-center">
        {renderSearchIcon()}
        {renderMobileIcons()}
        {renderUserActions()} {/* hidden in mobile view*/}
        {renderAgeVerIcon()}
      </div>
      {renderSearch()}
    </header>
  );
};

MainDashboardHeader.displayName = 'MainDashboardHeader';

export default MainDashboardHeader;

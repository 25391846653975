import React, { Component } from 'react';
import { ReactComponent as AngleDownIcon } from '../../images/NS_angle_down.svg';

import './DropdownSelect.scss';

class DropdownSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  onDropdownClick = () => {
    const { clearSearchValue } = this.props;
    const { show } = this.state;
    this.setState({ show: !show }, clearSearchValue());
  };

  renderOption = (option, index, onChangeSearchSection) => {
    const { name } = option;

    return (
      <li key={`${this.props.keyToUse}-${index}`} className="Option" onClick={() => onChangeSearchSection(option)}>
        {name}
      </li>
    );
  };

  render() {
    const { show } = this.state;
    const { options, onChangeSearchSection, selectedDropdownValue } = this.props;

    return (
      <div className="DropdownSelect cursor-pointer d-flex align-center justify-center" onClick={this.onDropdownClick}>
        <span className="SelectedTerm">{selectedDropdownValue?.name}</span>
        <AngleDownIcon style={{ width: '.5em' }} />
        <ul className={`DropdownSelectContent ${show ? 'active' : ''}`}>
          {options.map((option, index) => this.renderOption(option, index, onChangeSearchSection))}
        </ul>
      </div>
    );
  }
}

export default DropdownSelect;
